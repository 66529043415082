
/**
*
* Global animation classes
*
**/

//VARS
$defaultAnimationTime: 250ms;

/* property name | duration | timing function | delay */

@mixin animate-all($animationTime: $defaultAnimationTime, $delay: 0ms, $ease: ease) {
    transition: all  $animationTime $delay $ease;
}

@mixin animate-custom($animateProp, $animationTime: $defaultAnimationTime, $ease: ease, $delay: 0ms) {
    transition: $animateProp $animationTime $ease $delay;
}

@mixin animate-opacity($animationTime: $defaultAnimationTime) {
    transition: opacity  $animationTime ease;
}

@mixin animate-background($animationTime: $defaultAnimationTime) {
    transition: background $animationTime ease;
}

//Parse $svg variable if the element your animatiing is an SVG - method of animating is different
@mixin animate-color($animationTime: $defaultAnimationTime, $svg: false) {
    @if ($svg == false){
        transition: color $animationTime ease;
    }
    @else {
        transition: fill $animationTime ease;
    }
}

%animate-none {
    transition: none;
}


/*
Misc Keyframe animations
*/

//Slide element down
@keyframes slideDown{
    0% {
        transform: translateY(-100%);
    }

    100% {
        transform: translateY(0%);
    }
}

//Fade an element in
@keyframes fadeIn{
    from {
        opacity:0;
    } to {
          opacity:1;
      }
}


//Unblur an element
@keyframes blurIn{
    from {
        filter: blur(20px);
    } to {
          filter: blur(0px);
      }
}

//Grow an element
@keyframes grow {
    from {
        transform: scale(0.9);
    } to {

          transform: scale(1);
      }
}

//Grow an element
@keyframes grow_full {
    from {
        transform: scale(0);
    } to {

          transform: scale(1);
      }
}

//Shrink an element
@keyframes shrink {
    from {
        //transform: scale(1.075);
    } to {
          transform: scale(1);
      }
}

//Slide from left to right
@keyframes slideRight {
    from {
    } to {
          transform: translateX(0px);
          //opacity:1;
      }
}

//Rotate
@keyframes rotateClockwise {
    from {
        transform: rotate(0deg);
    } to {
          transform: rotate(359deg);
      }
}

@keyframes rotateAntiClockwise {
    from {
        transform: rotate(0deg);
    } to {
          transform: rotate(-359deg);
      }
}

@keyframes blurShrink {
    from {
        transform: scale(1.1) translateZ(0);
        filter: blur(40) grayscale(1);
    } to {
          transform: scale(1.0) translateZ(0);
          filter: blur(0) grayscale(1);
      }
}


/*
Reusable mixins for animations
*/

@mixin animate_fadeIn($delay: 1s, $duration: 3s) {
    opacity:0;  /* make things invisible upon start */
    animation: fadeIn cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: forwards;
    animation-duration: $duration;
    animation-delay: $delay;
}

@mixin animate_blurIn($delay: 1s, $duration: 3s) {
    //opacity:0.5;  /* make things invisible upon start */
    filter: blur(20px);
    animation: blurIn cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: forwards;
    animation-duration: $duration;
    animation-delay: $delay;
}

@mixin animate_shrink($delay: 1s, $duration: 15s, $initialScale: 1.075){
    transform: scale($initialScale);
    animation: shrink cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: forwards;
    animation-duration: $duration;
    animation-delay: $delay;
}

@mixin animate_grow($delay: 1s, $duration: 15s){
    transform: scale(0.9);
    animation: grow cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: forwards;
    animation-duration: $duration;
    animation-delay: $delay;
}

@mixin animate_fadeSlideRight($delay: 1s, $duration: 15s, $xOffset: -100px){
    transform: translateX($xOffset);
    animation:  slideRight cubic-bezier(0.19, 1, 0.22, 1),
    fadeIn cubic-bezier(0.19, 1, 0.22, 1);;
    animation-fill-mode: forwards;
    animation-duration: $duration;
    animation-delay: $delay;
}

@mixin grow_full($delay: 0s, $duration: 1s){
    transform: scale(0);
    animation: grow_full cubic-bezier(0.19, 1, 0.22, 1);
    animation-fill-mode: forwards;
    animation-duration: $duration;
    animation-delay: $delay;
}