@import '../shared';

#skills-graph {

    /*
    Core, default mobile first styles here
    */
    padding-top: 20px;
    margin-bottom: 30px;

    .graph {
        width: 100%;
        height: auto;
        position: relative;
        border-left: solid 1px rgba(white, 0.5);
        border-bottom: solid 1px rgba(white, 0.5);
        padding-bottom: 20px;
    }

    .vertical {
        position: absolute;
        z-index: 1;
        top: 0;
        width: 0;
        height: 100%;
        border-right: solid 1px rgba(white, 0.1);

        /*
        Position Vertical lines
         */
        @for $i from 1 through 5 {

            &:nth-child(#{$i}) {
                left: #{$i*20%};
            }
        }
    }

    .graph-key {
        display: flex;
    }

    .key-item {
        width: 20%;
        height: 24px;
        position: relative;
        border-right: solid 1px rgba(255,255,255,0.5);

        span {
            @include fontSize(14px);
            @include lineHeight(21px);
            @extend %font-utility;
            display: inline-block;
            position: absolute;
            width: 100%;
            right: 0;
            top: 0;
            padding: 2px 5px;
            background-color: $notBlack;
            text-transform: uppercase;
            text-align: right;
            color: rgba(255,255,255,0.5);
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        .key-item {

            span {
                width: auto;
                padding: 2px 8px;
            }
        }
    }
}
