@import '../shared';

.social-tiles {

    //Brand colour vars
    $youTube: #f00;
    $twitter: #1da1f2;
    $twitch: #6441A4;
    $defaultOpacity: 0.8;
    $hoverOpacity: 1;

    /*
    Core, default mobile first styles here
    */
    background: $notBlack;
    padding: 20px;
    padding-bottom: 0;
    height: 100%;
    margin-top: 20px;

    a {
        @include animate-custom(#{'background-color'});
        display: flex;
        position: relative;
        width: 100%;
        height: 60px;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        outline: none;
        margin-bottom: 20px;


        &.youtube {
            background-color: rgba($youTube, $defaultOpacity);
            &:hover {
                background-color: rgba($youTube, $hoverOpacity);
            }
        }

        &.twitter {
            background-color: rgba($twitter, $defaultOpacity);
            &:hover {
                background-color: rgba($twitter, $hoverOpacity);
            }
        }

        &.instagram {
            &:before {
                @include animate-custom(#{'opacity'});
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background:linear-gradient(45deg, #405de6, #5851db, #833ab4, #c13584, #e1306c, #fd1d1d);
                opacity: $defaultOpacity;
                z-index: 1;
            }
            &:hover {
                &:before {
                    opacity: $hoverOpacity;
                }
            }
        }

        &.twitch{
            background-color: rgba($twitch, $defaultOpacity);
            &:hover {
                background-color: rgba($twitch, $hoverOpacity);
            }
        }
    }

    .icon {
        @include fontSize(30px);
        position: relative;
        z-index: 2;
        color: white;
    }
}
