@import '../shared';

.scribbles-gallery-modal {

    /*
    Core, default mobile first styles here
    */
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(black, 0.8);
    z-index: 800;

    .close-wrapper {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 500;
        border-style: solid;
        border-width: 0 80px 80px 0;
        border-color: transparent $darkRed transparent transparent;
    }

    .btn-close {
        @include fontSize(23px);
        @include btnReset();
        display: block;
        position: fixed;
        right: 10px;
        top: 12px;
        width: 25px;
        height: 25px;
        color: rgba($cream, 0.8);

        &:hover {
            color: rgba($cream, 1);
        }
    }

    .swiper-slide .inner {
        width: 75%;
        max-width: 800px;
        padding: 10px;
        margin: auto;
        background: rgba(white, 0.8);
    }

    img {
        display: block;
        width: 100%;
        max-height: 80vh;
        margin: auto;
        object-fit: contain;
    }

    .desc {
        padding: 20px 10px 0 10px;
        color: $notBlack;

        h3 {
            margin-bottom: 0;
        }
        p {
            @include fontSize(14px);
        }
    }

    .swiper-wrapper {
        align-items: center;
    }

    .swiper-slide {
        opacity: 0.2;
        transition: opacity 500ms;
    }

    .swiper-slide-active {
        opacity: 1;
    }

    /*
    Swiper controls overrides
     */
    .swiper-button-prev,
    .swiper-button-next {
        @include fontSize(30px);
        @include btnReset();
        font-family: 'IcoMoon-Free' !important;
        speak: none;
        font-style: normal;
        font-weight: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        color: rgba($cream, 0.8);

        //top: initial;
        //bottom: 0;

        &:after {
            display: none;
        }

        &:hover {
            color: rgba($cream, 1);
        }
    }

    .swiper-button-prev {
        left: 10px;

        &:before {
            content: "\ea44";
        }

        &:active {
            transform: translateX(-2px) scale(0.95);
        }

    }
    .swiper-button-next {
        right: 10px;

        &:before {
            content: "\ea42";
        }

        &:active {
            transform: translateX(2px) scale(0.95);
        }
    }


    //992px +
    @include media-breakpoint-up(lg) {
        .close-wrapper {
            border-width: 0 120px 120px 0;
        }

        .btn-close {
            @include fontSize(30px);
            right: 20px;
            top: 20px;
            width: 35px;
            height: 35px;
        }

        .desc {
            h3 {
                @include fontSize(22px);
            }
            p {
                @include fontSize(16px);
            }
        }

        .swiper-slide .inner {
            width: 60%;
        }

        .swiper-button-prev,
        .swiper-button-next {
            top: 50%;
            bottom: initial;
        }

        .swiper-button-prev {
            left: 30px;

        }
        .swiper-button-next {
            right: 50px;
        }
    }
}
