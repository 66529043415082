@import '../shared';
@import '../ui/dropdown';

.skill-dropdown {

    /*
    Core, default mobile first styles here
    */
    $skillsFilter_buttonHeight: 30px;

    .filter-controls {
        display: flex;
        margin-bottom: 20px;
    }

    .filter-text {
        @include fontSize(24px);
        @include lineHeight(29px);
        min-width: 110px;
        color: $midRed;
        font-family: $fontLobster;
        padding-right: 20px;
    }

    .dropdown {
        margin-left: auto;
    }

    //992px +
    @include media-breakpoint-up(lg) {
        .filter-controls {
            justify-content: flex-end;
        }
    }
}
