@mixin btnReset(){
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    outline: none;
}

@mixin btnDisabled(){
    cursor: default;
    pointer-events: none;
}

@mixin btnAngled($size: 'default'){
    @if $size == 'large' {
        @include fontSize(26px);
    } @else {
        @include fontSize(20px);
    }

    @include animate-custom(
        $animateProp: transform,
        $animationTime: 250ms,
        $ease: ease-out,
        $delay: $defaultAnimationTime * 10
    );

    display: inline-block;
    position: relative;
    font-family: $fontLobster;
    color: white;
    text-decoration: none;

    .inner {
        @include animate-custom(#{'width'}, 250ms);
        position: relative;
        z-index: 5;
        width: 100%;
        transform: translateX(-50%);
        left: 50%;

        @if $size == 'large' {
            padding: 15px 20px 15px 40px
        } @else {
            padding: 6px 12px 6px 28px;
        }

        &:before {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            background: $darkRed;
            transform: skew(-$globalSkew);
        }

        &:after {
            @include animate-custom(#{'width'}, 150ms);
            content: '';
            display: block;
            width: 5px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            background: $midRed;
            transform: skew(-$globalSkew);
        }
    }

    .icon {
        @include animate-custom(#{'transform'}, 250ms);
        display: inline-block;
        padding: 0 10px;

        @if $size == 'large' {
            @include fontSize(20px);
        } @else {
            @include fontSize(16px);
        }
    }

    .txt,
    .icon {
        position: relative;
        z-index: 5;
    }

    &:not(:disabled){
        &:hover {
            color: white;
            text-decoration: none;

            .inner {
                width: 120%;

                &:after {
                    width: 100%;
                }
            }

            .icon {
                transform: translateX(5px);
            }
        }
    }


    &:disabled {
        cursor: default;
        pointer-events: none;
        opacity: 0.5;
    }

            //992px +
    @include media-breakpoint-up(lg) {
        @if $size == 'large' {

            @include fontSize(35px);
            .inner {
                padding: 20px 30px 20px 50px;
            }
            .icon {
                @include fontSize(26px);
            }

        } @else {

            @include fontSize(26px);
            .inner {
                padding: 8px 20px 8px 35px;
            }
            .icon {
                @include fontSize(18px);
            }
        }
    }
}