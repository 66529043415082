@import '../shared';

#transition-mask{
    $maskAnimationTime: 500ms;
    $easeOut: cubic-bezier(0.25, 1, 0.5, 1);
    $easeIn: cubic-bezier(0.5, 0, 0.75, 0);

    .reset {
        position: absolute;
        left: 200px;
        top: 80px;
    }
    /*
    Core, default mobile first styles here
    */
    @include animate-custom(#{'background-color'}, $maskAnimationTime);
    position: fixed;
    left: -100px;
    top: 0;
    z-index: 100;
    width: calc(120vw + 200px);
    height: 100vh;
    pointer-events: none;
    background-color: rgba(black, 0.0);

    .inner {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
    }

    .layer-slice {
        width: 250px;
        height: 100%;
        position: relative;
        transform: skew(-$globalSkew);
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            width: 101%;
            height: 100%;
            transform: translateX(-101%);
        }
    }

    @for $i from 1 through 11 {
        .layer-slice:nth-child(#{$i}) {
            left: -($i+1)+px;
        }
    }

    &.transition-in {
        background-color: rgba(black, 0.5);

        .layer-slice:before {
            @include animate-custom(#{'transform'}, $maskAnimationTime, $easeOut);
            transform: translateX(0);
            background: rgba($darkRed, 1);
        }

        @for $i from 1 through 11 {
            .layer-slice:nth-child(#{$i}):before {
                left: -($i+1)+px;
                transition-delay: $i*75ms;
            }
        }
    }

    &.transition-out {
        background-color: rgba(black, 0.0);
        transition-delay: 200ms;

        .layer-slice:before {
            @include animate-custom(#{'transform'}, $maskAnimationTime, $easeOut);
            transform: translateX(101%);
            background: rgba($darkRed, 1);
        }

        @for $i from 1 through 11 {
            .layer-slice:nth-child(#{$i}):before {
                left: -($i+1)+px;
                transition-delay: ($i*75ms);
            }
        }
    }
}
