@import '../shared';

.bar-preloader {
    /*
    Core, default mobile first styles here
    */
    overflow: hidden;
    position: relative;
    height: 5px;
    background: rgba($midRed, 0.5);

    &::before {
        content: '';
        position: absolute;
        left: -130%;
        width: 100%;
        height: 100%;
        background: $darkRed;
        animation: barProgress 4s linear infinite;
    }

    @keyframes barProgress{
        0%{
            left: -130%;
            background: rgba($darkRed, .5);
        }
        50%{
            left: 130%;
            background: rgba($darkRed, 1);
        }
        51%{
            background: rgba($white, 1);
        }
        100%{
            background: rgba($white, .5);
        }
    }
}