@import '../shared';

.secondary-nav-links {
    /*
    Core, default mobile first styles here
    */
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
        margin-bottom: 10px;
    }

    button {
        @include btnReset();
    }
    a, button {
        @include fontSize(26px);
        @include animate-all(250ms);
        color: white;
        text-decoration: none;

        &:hover {
            opacity: 1;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        li {
            margin-bottom: 20px;
        }

        a,button {
            @include fontSize(30px);
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }
    }
}
