@import '../shared';

#scribbles {

    /*
    Core, default mobile first styles here
    */
    background-image: url('../../imgs/page-bgs/scribbles.jpg');
    background-size: cover;
    background-attachment: fixed;
    color: white;
    background-color: $cream;

    .title-row {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        text-align: center;
    }

    h1 {
        @include fontSize(30px);
        @include lineHeight(36px);
        max-width: 300px;
        font-family: $fontLobster;
        color: $darkRed;
        margin: 0 auto;
    }

    .star-block {
        display: none;
    }

    .intro-row {
        margin-bottom: 40px;

        p {
            @include fontSize(22px);
            @include lineHeight(30px);
            font-family: $fontCrimson;
            color: $notBlack
        }
    }


    //768px +
    @include media-breakpoint-up(md) {
        h1 {
            max-width: 100%;
        }
    }


    //992px +
    @include media-breakpoint-up(lg) {

        .title-row {
            margin-bottom: 40px;
        }

        h1 {
            @include fontSize(32px);
            @include lineHeight(32px);
            padding: 0;
        }

        .star-block {
            display: flex;
            .icon {
                @include fontSize(12px);
                color: #BAB6A4;
                display: inline-block;
            }
        }

        .star-block:first-child {
            margin-right: auto;
            .icon {
                padding-right: 15px;
            }
        }

        .star-block:last-child {
            margin-left: auto;
            .icon {
                padding-left: 15px;
            }
        }

        .intro-row {
            margin-bottom: 60px;

            p {
                @include fontSize(26px);
                @include lineHeight(34px);
            }
        }
    }

    //1200px +
    @include media-breakpoint-up(xl) {
        h1 {
            @include fontSize(34px);
            @include lineHeight(32px);
        }
    }
}
