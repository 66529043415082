@import '../shared';

.portfolio-default {
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    left: 0;
    top: 0;
    align-items: flex-end;
    padding: 0 20px 70px 20px;

    .item-logo {
        width: 100%;
        max-width: 140px;
    }

    .item-title {
        @include fontSize(18px);
        @include lineHeight(20px);
        font-family: $fontCrimson;
        margin-bottom: 0;
        color: white;
    }

    .btn-view-detail--sm {
        @include btnReset();
        margin-left: auto;
        margin-top: 10px;
    }

    .btn-view-detail--lg {
        display: none;
    }

    //992px +
    @include media-breakpoint-up(lg) {

        justify-content: flex-end;
        padding: 0 70px 50px 0;

        .btn-view-detail--sm {
            display: none;
        }

        .logo-name-block {
            text-align: right;
        }

        .item-logo {
            float: right;
            width: 180px;
            max-width: 180px;
            margin-bottom: 10px;
        }

        .btn-view-detail--lg {
            @include btnReset();
            display: block;
            position: absolute;
            transform: skew(-$globalSkew);
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }

        /*
        Override the default fade class timings
         */
        &.fade-exit-active {
            opacity: 0;
            transition-duration: 50ms;
        }
    }
}
