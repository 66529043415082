@import '../shared';

.skills-description{

    /*
    Core, default mobile first styles here
    */
    //background: rgba(red, 0.3);

    h1 {
        @include fontSize(22px);
        @include lineHeight(28px);
        @extend %font-utility;
        text-transform: uppercase;

        //color: $midRed;
        margin-bottom: 15px;
    }

    p {
        color: $cream;
    }

    //576px +
    @include media-breakpoint-up(sm) {

    }

    //768px +
    @include media-breakpoint-up(md) {

    }

    //992px +
    @include media-breakpoint-up(lg) {

    }

    //1200px +
    @include media-breakpoint-up(xl) {

    }

}
