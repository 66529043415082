@import '../shared';

#skillset {

    /*
    Core, default mobile first styles here
    */
    background-image: url('../../imgs/page-bgs/skillset.jpg');
    background-size: cover;
    background-attachment: fixed;
    color: white;
    background-color: $cream;
    padding-bottom: 40px;

    h1 {
        font-weight: normal;
    }

    .intro-row {
        margin-bottom: 40px;

        p {
            @include fontSize(22px);
            @include lineHeight(30px);
            font-family: $fontCrimson;
            color: $cream;
        }
    }

    .faux-border {
        position: relative;
        display: flex;
        padding-top: 20px;

        &:before {
            content: '';
            display: block;
            width: 75%;
            height: 1px;
            background-color: rgba(white, 0.2);
            margin: auto;
        }
    }

    .text-desc-block {
        p, li {
            @include fontSize(14px);
            @include lineHeight(24px);
        }
    }

    .selected-skill-row {
        display: flex;

        .skill-name {
            @include fontSize(24px);
            @include lineHeight(29px);
            color: $cream;
            font-family: $fontLobster;
            margin: 0;
        }
    }

    /*
    Key Legend
     */

    .key-legend-tooltip {
        padding: 15px;
        background-color: lighten($notBlack, 3%);
        border-radius: 5px;
        opacity: 1;
    }

    .tooltip-wrap {
        max-width: 300px;
    }

    .legend-item {
        position: relative;
        display: flex;
        margin-bottom: 5px;

        .legend-name {
            width: 75px;
            flex-shrink: 0;
        }

        span {
            @include fontSize(14px);
            @include lineHeight(21px);
            @extend %font-utility;
            padding: 2px 8px;
            height: 24px;
            background-color: $notBlack;
            text-transform: uppercase;
            color: rgba(255,255,255,0.5);
        }

        .desc {
            @include fontSize(12px);
            @include lineHeight(24px);
            color: darken(white, 20%);
            padding-left: 20px;
            margin: 0;
        }
    }

    .btn-tooltip {
        @include btnReset();
        @include fontSize(16px);
        display: block;
        font-weight: bold;
        width: 24px;
        height: 24px;
        margin-left: auto;
        border: solid 2px $cream;
        border-radius: 50%;
        align-self: flex-end;
        color: $cream;
        opacity: 0.5;

        span {
            position: relative;
            top: -3px;
        }

        &:hover {
            color: $midRed;
            opacity: 1;
            border-color: $midRed;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {

        .intro-row {
            h1 {
                @include fontSize(32px);
                @include lineHeight(32px);
                padding: 0;
            }
        }

        .btn-tooltip {
            span {
                position: relative;
                top: -4px;
            }
        }
    }
}
