@import '../shared';

.feature-img {

    /*
    Core, default mobile first styles here
    */
    position: relative;

    .aspect-block {
        @include aspectRatio(600,360);
    }

    .img-block {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $darkRed;
        box-shadow: 0 13px 29px 0 rgba(0, 0, 0, 0.3);
    }

    .img-inner {
        clip-path: polygon(8% 0%, 100% 0%, 92% 100%, 0% 100%);


    }

    img {
        display: block;
        width: 100%;
    }

    .content-layer {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        padding: 22px 28px;
        width: 100%;
        height: 100%;
        align-items: flex-end;
    }

    .tag-line {
        @include fontSize(22px);
        @include lineHeight(26px);
        font-family: $fontCrimson;
        font-weight: bold;
        color: $darkRed;
        margin: 0;
        padding: 30px 0 20px 0;

        &.lg {
            display: none;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        .aspect-block {
            @include aspectRatio(1180,560);
        }

        .img-inner:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 0.75;
            //background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
            background: linear-gradient(0deg, black 3%, rgba(0, 0, 0, 0) 35%);
        }

        .content-layer {
            padding: 50px;
        }

        .tag-line {
            @include fontSize(30px);
            @include lineHeight(32px);
            color: white;
            padding: 0;
            text-align: left;

            &.sm { display: none; }
            &.lg { display: block; }
        }
    }
}
