@import '../shared';

.faq {

    /*
    Core, default mobile first styles here
    */
    background-color: rgba($notBlack, 0.8);
    padding: 20px;
    height: 100%;

    .item {
        padding-bottom: 20px;
    }

    .question {
        @include fontSize(20px);
        font-family: $fontLobster;
        color: $eggShell;
        margin: 0;
    }

    .answer {
        @include fontSize(14px);
        margin: 0;
    }

    //992px +
    @include media-breakpoint-up(lg) {
        background-color: transparent;
    }
}
