@import '../shared';

#text-preloader {
    $animationDuration: 1500ms;

    /*
    Core, default mobile first styles here
    */
    width: 100%;

    @keyframes wipeGrow {
        0% {
            left: 0;
            width: 0;

        }
        50% {
            left: 0;
            width: 100%;
        }
        51% {
            right: 0;
            left: auto;
            width: 100%;
        }
        100% {
            right: 0;
            left: auto;
            width: 0;
        }
    }


    .inner {
        display: block;
        position: relative;
        padding: 0 20px;
        height: 40px;
        text-align: center;

        &:after {
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1;
            transform: skew(-$globalSkew);
            background-color: $darkRed;
            animation: wipeGrow $animationDuration infinite;
        }
    }

    p {
        @include fontSize(30px);
        @include lineHeight(36px);
        max-width: 300px;
        font-family: $fontLobster;
        color: $darkRed;
        margin: 0 auto;
    }

    //992px +
    @include media-breakpoint-up(lg) {

        p {
            @include fontSize(32px);
            @include lineHeight(32px);
            padding: 0;
        }
    }

    //1200px +
    @include media-breakpoint-up(xl) {
        p {
            @include fontSize(34px);
            @include lineHeight(32px);
        }
    }
}
