$fontAlpha: 'Alfa Slab One', cursive;
$fontCrimson: 'Crimson Text', serif;
$fontLobster: 'Lobster', cursive;
$fontMontserrat: 'Montserrat', sans-serif;
$fontOpenSans: 'Open Sans', sans-serif;
$fontRoboto: 'Roboto', sans-serif;
$fontRobotoCondensed: 'Roboto Condensed', sans-serif;

%font-header {
    font-family: $fontAlpha;
}

%font-sub-header {
    font-family: $fontMontserrat;
}

%font-body {
    font-family: $fontMontserrat;
}

%font-utility {
    font-family: $fontRobotoCondensed;
}

