@import '../shared';

#about {
    /*
    Core, default mobile first styles here
    */
    background-image: url('../../imgs/page-bgs/about.jpg');
    background-size: cover;
    background-attachment: fixed;
    color: white;
    padding-bottom: 40px;

    .about-tabs {
        ul {
            display: flex;
            margin: 0;
            padding: 0
        }
        li {
            display: flex;
            flex-grow: 1;
            list-style-type: none;
            justify-content: center;
            border-right: solid 1px black;

            &:last-child {
                border-right: none;
            }
        }
    }

    .btn-tab {
        @include btnReset();
        @include fontSize(20px);
        width: 100%;
        padding: 5px 0;
        background-color: rgba($notBlack, 0.5);
        color: $eggShell;
        font-family: $fontLobster;

        span {
            display: inline-block;
            margin: 0 5px;
        }

        .icon {
            @include fontSize(16px);
        }

        .txt {
            display: none;
        }

        &:hover {
            color: white;
        }

        &.active {
            color: white;
            background-color: rgba($notBlack, 0.8);
        }
    }

    //Prevent excessive page height
    .swiper-slide:not(.swiper-slide-active){
        max-height: 50vh;
    }

    .about-content-intro {
        background: rgba($notBlack, 0.8);
        padding: 20px;
        margin-bottom: 20px;

        p {
            @include fontSize(18px);
            @include lineHeight(26px);
            font-family: $fontCrimson;
            color: white;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .preferred-tools {
        background: $notBlack;
        padding: 10px;
        padding-bottom: 0;

        img {
            width: 100%;
            margin-bottom: 20px;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {

        .btn-tab {
            padding: 10px 30px;

            .icon {
                @include fontSize(22px);
            }
            .txt {
                display: inline;
            }
        }

        .about-content-intro {
            padding: 30px 40px;
            margin-bottom: 40px;

            p {
                @include fontSize(22px);
                @include lineHeight(32px);
            }
        }

        .quick-facts {
            background-color: rgba($notBlack, 0.8);
        }

        .preferred-tools {
            padding: 30px 30px 20px 30px;
            margin-bottom: 40px;
        }
    }

}
