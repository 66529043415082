@import '../shared';

.tabbed-content {
    $tabBgColour: #ede9e4;

    /*
    Core, default mobile first styles here
    */
    width: 100%;

    /*
    Tab Buttons
     */
    .btn-tab {
        @include btnReset();
        @include fontSize(18px);
        padding: 7px 14px;
        margin-right: 2px;
        background-color: rgba($tabBgColour, 0.5);
        color: $darkRed;
        font-family: $fontLobster;

        &:hover {
            color: $midRed;
        }

        &.active {
            background-color: $tabBgColour;
        }
    }

    /*
    Content blocks
     */
    .tab-content-block {
        background-color: $tabBgColour;
    }

    .tab-content {
        display: none; //default
        padding: 20px;

        &.active {
            display: block;
        }
    }

    p, ul, ol, li {
        //@include fontSize(20px);
        //@include lineHeight(24px);
        font-family: $fontCrimson;
        color: $notBlack;
    }


    //576px +
    @include media-breakpoint-up(sm) {

    }

    //768px +
    @include media-breakpoint-up(md) {

    }

    //992px +
    @include media-breakpoint-up(lg) {
        margin-top: 50px;

        /*
        Tab Buttons
         */
        .btn-tab {
            @include fontSize(26px);
            padding: 8px 20px;
            margin-right: 2px;
        }

        .tab-content {
            padding: 25px;
        }

        p {
            //@include fontSize(26px);
            //@include lineHeight(32px);
        }
    }

    //1200px +
    @include media-breakpoint-up(xl) {

    }

}
