@import '../shared';

.skill-item {

    /*
    Core, default mobile first styles here
    */
    //Individual Skill items
    $skillItemHeight: 30px;
    $skillItemTransitionLength: 750ms;

    position: relative;
    z-index: 5;
    height: $skillItemHeight;
    width: 0; //Default width, before animate
    background: $darkRed;
    margin-bottom: 10px;
    overflow: hidden;
    color: white;


    &:after {
        content: '';
        display: block;
        width: 2px;
        height: 100%;
        position: absolute;
        top: 0;
        right: 0;
        background-color: $midRed;
    }

    /*
    Position based animation delay
     */
    @for $i from 1 through 10 {
        $baseSpeed: 0;
        $increment: 50;
        $itemDelay: #{$baseSpeed + ($increment * $i)}ms;

        &:nth-child(#{$i}) {
            transition-delay: $itemDelay;
        }
    }

    .label {
        @include fontSize(15px);
        @include lineHeight($skillItemHeight);
        @extend %font-utility;
        position: absolute;
        left: 10px;
        top: 0;
        text-transform: uppercase;
        color: rgba(white, 0.9);
    }


    //DEBUG
    @for $i from 1 through 10 {
        &[data-rating='#{$i}'] {
            width: #{$i * 10 +'%'};
        }
    }
    //&.added {
    //    @for $i from 1 through 10 {
    //        &[data-rating='#{$i}'] {
    //            width: #{$i * 10 +'%'};
    //        }
    //    }
    //}



    /*
    Default fade in / ou
     */
    &.enter {
        width: 0;
    }

    &.enter-active {
        //width: 100%;
        //transition: width $skillItemTransitionLength cubic-bezier(0.25, 1, 0.5, 1);
        transition-property: width;
        transition-duration: $skillItemTransitionLength;
        transition-timing-function:  cubic-bezier(0.25, 1, 0.5, 1)
    }

    &.enter-active {
        //width: 100%;
        @for $i from 1 through 10 {
            &[data-rating='#{$i}'] {
                width: #{$i * 10 +'%'};
            }
        }
    }

    &.exit {
        //width: 100%;
        @for $i from 1 through 10 {
            &[data-rating='#{$i}'] {
                width: #{$i * 10 +'%'};
            }
        }
    }

    &.exit-active {
        width: 0 !important;
        //transition: width $skillItemTransitionLength cubic-bezier(0.5, 0, 0.75, 0);
        transition-property: width;
        transition-duration: $skillItemTransitionLength;
        transition-timing-function:  cubic-bezier(0.25, 1, 0.5, 1)
    }

    &.exit-done {
        width: 0 !important;
    }


    //576px +
    @include media-breakpoint-up(sm) {

    }

    //768px +
    @include media-breakpoint-up(md) {

    }

    //992px +
    @include media-breakpoint-up(lg) {

    }

    //1200px +
    @include media-breakpoint-up(xl) {

    }

}
