@import '../shared';

$portfolio_skewAmount: $globalSkew;

.portfolio-item {
    /*
    Common structural
     */
    height: 100vh;
    width: 100%;
    position: relative;
    overflow: hidden;

    .bg-image {
        @include animate-all(250ms);
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background-size: cover;
        background-repeat: no-repeat;
        pointer-events: none;

        &:after {
            @include animate-all();
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(black, 0.1); //0.3 og
        }
    }

    > .inner {
        position: absolute;
        width: 100%;
        max-width: 100vw;
        height: 100%;
        background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.1) 20%);
    }

    &.active {
        .bg-image {
            &:after {
                background-color: rgba(black, 0.8);
            }
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        /*
        Common structural
         */
        transform: skew(-$portfolio_skewAmount);
        cursor: pointer;

        .bg-image {
            width: calc(100vw + 70px);

            &:after {
                background-color: rgba(black, 0.3); //0.3 og
            }
        }

        > .inner {
            transform: skew($portfolio_skewAmount);
            background: none;
        }

        /*
        Single Portfolio Item, background image treatments for hover + active
         */
        &:hover,
        &.active {
            cursor: default;

            .bg-image {
                left: -5px;

                &:after {
                    background-color: rgba(black, 0.1);
                }
            }
        }

        &.active {
            .bg-image:after {
                background-color: rgba(black, 0.8);
            }
        }
    }
}