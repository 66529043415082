@import '../shared';

.testimonials{

    /*
    Core, default mobile first styles here
    */
    @extend %font-body;

    .testimonial-item {
        background: rgba($notBlack, 0.8);
        margin-bottom: 40px;

        p {
            @include fontSize(16px);
            @include lineHeight(28px);
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    .main {
        position: relative;
        padding: 20px 20px;

        &:before {
            @include fontSize(30px);
            font-family: 'IcoMoon-Free';
            content: "\e977";
            display: block;
            position: absolute;
            left: 10px;
            top: 10px;
            opacity: 0.3;
        }

        p:last-child {
            position: relative;
            display: inline-block;
        }

        .icon-quotes-right {
            @include fontSize(30px);
            font-family: 'IcoMoon-Free';
            display: inline-block;
            position: absolute;
            right: -10px;
            bottom: 0;
            opacity: 0.3;
        }
    }

    .author {
        padding: 0 20px 20px 20px;
        display: flex;

        .img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 20px;
        }

        img {
            width: 100%;
            height: 100%;
            filter: grayscale(100%);
        }

        p {
            margin: 0;
        }

        .name {
            @include fontSize(22px);
            font-family: 'Crimson Text', serif;
        }
        .title {
            @include fontSize(14px);
            font-style: italic;
        }

    }

    //768px +
    @include media-breakpoint-up(md) {

    }

    //992px +
    @include media-breakpoint-up(lg) {

        .main {
            position: relative;
            padding: 40px 60px;

            &:before {
                @include fontSize(70px);
                top: 27px;
            }

            .icon-quotes-right {
                @include fontSize(70px);
                right: -60px;
                top: -20px;
                bottom: auto;
            }
        }

        .author {
            padding: 0 60px 40px 60px;
        }
    }

    //1200px +
    @include media-breakpoint-up(xl) {

    }

}
