body {
    font-family: $fontRoboto;
}

%h1 {
    @include fontSize(24px);
    @include lineHeight(28px);
    @extend %font-header;
    margin-bottom: 14px;
    font-weight: normal;

    @include media-breakpoint-up(md) {
        @include fontSize(40px);
        @include lineHeight(56px);
        margin-bottom: 32px;
    }
}

%h2 {
    @include fontSize(20px);
    @include lineHeight(32px);
    @extend %font-header;
    margin-bottom: 12px;
    font-weight: normal;

    @include media-breakpoint-up(lg) {
        @include fontSize(34px);
        @include lineHeight(38px);
        margin-bottom: 20px;
    }
}

%h3 {
    @include fontSize(18px);
    @include lineHeight(28px);
    @extend %font-header;
    margin-bottom: 12px;
    font-weight: normal;

    @include media-breakpoint-up(lg) {
        @include fontSize(24px);
        @include lineHeight(30px);
        margin-bottom: 16px;
    }
}

%h4 {
    @include fontSize(16px);
    @include lineHeight(24px);
    margin-bottom: 8px;
    font-weight: normal;

    @include media-breakpoint-up(lg) {
        @include fontSize(20px);
        @include lineHeight(32px);
        margin-bottom: 12px;
    }
}

%h5 {
    @include fontSize(14px);
    @include lineHeight(22px);
    margin-bottom: 8px;
    font-weight: normal;

    @include media-breakpoint-up(lg) {
        @include fontSize(16px);
        @include lineHeight(24px);
        margin-bottom: 12px
    }
}

%h6 {
    @include fontSize(10px);
    @include lineHeight(16px);
    margin-bottom: 12px;
    font-weight: normal;

    @include media-breakpoint-up(lg) {
        @include fontSize(12px);
        @include lineHeight(16px);
        margin-bottom: 16px
    }
}

%body {
    @include fontSize(18px);
    @include lineHeight(24px);
    @extend %font-body;

    @include media-breakpoint-up(lg) {
        @include fontSize(22px);
        @include lineHeight(28px);
    }
}

h1 {
    @extend %h1;
}

h2 {
    @extend %h2;
}

h3 {
    @extend %h3;
}

h4 {
    @extend %h4;
}

h5 {
    @extend %h5;
}

h6 {
    @extend %h6;
}

body,
p,
ul,
li {
    @extend %body;
}

