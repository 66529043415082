@import '../shared';

.text-img-block {

    /*
    Core, default mobile first styles here
    */
    padding-bottom: 65px;

    .img-block {
        margin-bottom: 20px;
    }

    img {
        width: 100%;
        box-shadow: 0 13px 29px 0 rgba(0, 0, 0, 0.1);
    }

    p {
        //@include fontSize(18px);
        //@include lineHeight(24px);
        font-family: $fontCrimson;
        color: $notBlack;
        margin: 0;

        &:first-child {
            @include fontSize(26px);
            @include lineHeight(30px);
            margin-bottom: 10px;
            font-weight: bold;
            color: $darkRed;
        }
    }


    //576px +
    @include media-breakpoint-up(sm) {

    }

    //768px +
    @include media-breakpoint-up(md) {

    }

    //992px +
    @include media-breakpoint-up(lg) {
        .img-block {
            margin-bottom: 0;
        }

        p {
            @include fontSize(20px);
            @include lineHeight(26px);

            &:first-child {
                @include fontSize(26px);

            }
        }
    }

    //1200px +
    @include media-breakpoint-up(xl) {

    }

}
