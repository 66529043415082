/*
Colour variables
 */

$midRed: #c70537;
$darkRed: #870026;
$midBlue: #529ccd;
$notWhite: #f7f7f7;
$notBlack: #161716;
$brown: #231105;
$eggShell: #EAEAD3;
$cream: #f7f2ec;
