/*
Overrides for 3rd party dropdown component
Hence class naming conventions use different
formatting and casing
 */

.Dropdown-root {

    @mixin dropDownTypography(){
        @include fontSize(14px);
        @include lineHeight(18px);
        @extend %font-utility;
        text-transform: uppercase;
    }
    /*
    Core, default mobile first styles here
    */
    width: 225px;

    .Dropdown-control {
        @include dropDownTypography();
        text-transform: uppercase;
        border: none;
        border-radius: 0;
        background: $notBlack;
        color: rgba(white, 0.8);
        cursor: pointer;
    }

    .Dropdown-placeholder.is-selected {
        color: rgba(white, 1);
    }

    .Dropdown-arrow {
        border-width: 5px 5px 0;
        top: 13px;
        right: 13px;
    }


    .Dropdown-menu {
        max-height: 999px;
        border: none;
        border-radius: 0;
        background: $notBlack;
    }

    .Dropdown-option {
        @include dropDownTypography();
        border: none;
        border-top: solid 1px lighten($notBlack, 20%);
        border-radius: 0;
        background-color: $notBlack;
        color: rgba(white, 0.8);

        &:hover {
            color: rgba(white, 1);
            background-color: lighten($notBlack, 10%);
        }

        &.is-selected {
            color: rgba(white, 1);
        }
    }

    &.is-open {
        .Dropdown-arrow {
            border-color: #999 transparent transparent;
            transform: rotate(180deg);
        }
    }


    //576px +
    @include media-breakpoint-up(sm) {

    }

    //768px +
    @include media-breakpoint-up(md) {

    }

    //992px +
    @include media-breakpoint-up(lg) {
        width: 225px;
    }

    //1200px +
    @include media-breakpoint-up(xl) {
        width: 300px;
    }

}
