@import '../shared';

.currently {

    /*
    Core, default mobile first styles here
    */
    background-color: rgba($notBlack, 0.8);
    padding: 20px;
    height: 100%;
    margin-top: 20px;

    .item {
        display: flex;
        width: 100%;
        padding-bottom: 20px;
    }

    .logo {
        display: flex;
        width: 50px;
        align-items: center;
        flex-shrink: 0;

        .icon {
            @include fontSize(25px);
        }
    }

    .content {
        .title {
            @include fontSize(20px);
            font-family: $fontLobster;
            color: $eggShell;
            margin: 0;
        }

        .desc {
            @include fontSize(14px);
            margin: 0;
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        background-color: transparent;
        margin-top: 0;
    }
}
