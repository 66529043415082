@import '../shared';

$headerLogoWidth_mobile: 80px;
$headerLogoWidth: 110px;

#global-header {

    /*
    Core, default mobile first styles here
    */
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 100%);

    &:before {
        @include animate-custom(#{'background-color'}, 250ms);
        content: '';
        position: fixed;
        display: block;
        width: 100vw;
        height :100vh;
        background-color: rgba(black, 0);
        pointer-events: none;
    }

    > .inner {
        position: relative;
        padding: 10px;
    }

    .logo {
        margin: 0;
        padding: 0;
        width: $headerLogoWidth_mobile;
        float: right;

        img {
            width: 100%;

            &:focus {
                border: none;
                outline: 0;
            }
        }
    }

    .btn-menu-trigger {
        position: relative;
        top: 4px;
        outline: none;
        display: block;
        padding: 12px 10px;
        z-index: 10;
        float: left;

        &:focus,
        &:active {
            outline: none;
        }
    }

    /*
    Hide the secondary nav for mobile
     */
    #secondary-nav {
        display: none;
    }

    /*
    Nav Open state
     */
    &.nav-open {
        &:before {
            background-color: rgba(black, 0.5);
        }

        .btn-menu-trigger {
            opacity: 1;
        }
    }

    /*
    When contact form modal active
     */
    &.show-dimmer {
        &:before {
            background-color: rgba(black, 0.5);
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {

        /*
        Core structure
         */
        width: 80px;
        height: 100vh;


        > .inner {
            padding: 0;
            height: 100%;
            background: $darkRed;
        }

        .logo {
            position: fixed;
            top: 10px;
            right: 20px;
            width: $headerLogoWidth;
            z-index: 50;
            float: none;
        }

        .btn-menu-trigger {
            left: 0;
            top: 28px;
            margin: auto;
            opacity: 0.5;
            z-index: 10;
            float: none;

            &:hover {
                opacity: 1;
            }

            &:focus,
            &:active {
                outline: none;
            }
        }

        /*
        Reposition secondary nav
         */
        #secondary-nav {
            display: block;
            position: absolute;
            bottom: 16px;
            left: 23px;
        }
    }
}
