@import '../shared';

.portfolio-detail.fade-enter-done {
    .fade-up-enter-done {
        .btn-back {
            bottom: 0 !important;
        }
    }
}

.portfolio-detail {
    /*
    Core, default mobile first styles here
    */
    max-width: 100vw;

    .simplebar-track.simplebar-vertical {
        right: 2px;
    }

    .btn-back {
        @include animate-all();
        @include btnReset();
        position: fixed;
        bottom: -40px;
        z-index: 999;
        width: 100vw;
        height: 40px;
        background-color: $darkRed;
        color: White;

        .icon {
            @include fontSize(16px);
        }

        .txt {
            @include fontSize(22px);
            font-family: $fontLobster;
            display: inline-block;
            margin: 0 10px;
        }
    }

    .detail-inner-wrap {
        padding: 0 $grid-gutter-width / 2;
        margin: auto;
        position: relative;
        color: $notBlack;
    }

    .detail-content-layer{

        &:before {
            content: '';
            display: block;
            background-color:  lighten(#f8f3ef, 2%);
            position: absolute;
            left: 0;
            top: 240px;
            width: 100%;
            height: 100%;
        }
    }

    /*
    Title block + children
     */
    .title-row {
        padding-top: 80px;
        padding-bottom: 30px;
    }

    .item-title {
        @include fontSize(30px);
        @include lineHeight(36px);
        font-family: $fontLobster;
        color: $midRed;
        margin-bottom: 0;
        flex-basis: 100%;
    }

    .item-label {
        @include fontSize(18px);
        @include lineHeight(24px);
        font-family: $fontCrimson;
        color: white;
        margin: 0;
        flex-basis: 100%;
    }

    /*
    Logo
     */
    .logo-col { display: none}

    /*
    Intro-block
     */
    .intro-block {
        padding: 0 0 40px 0;

        p {
            font-family: $fontCrimson;
            margin: 0;
        }
    }

    .cta-row {
        margin: 40px 0;
        text-align: center;
        position: relative;
        overflow: hidden;
    }

    .disclaimer {
        @include fontSize(17px);
        @include lineHeight(18px);
        font-family: $fontCrimson;
        margin-top: 40px;
        color: rgba($notBlack, 0.6);
    }

    //992px +
    @include media-breakpoint-up(lg) {
        height: 100%;
        margin: auto;
        position: relative;

        .btn-back {
            @include animate-custom(#{'background-color'}, 250ms);
            top: 36px;
            left: 100px;
            width: 40px;
            height: 40px;
            border-radius: 50%;

            .txt {
                display: none;
            }

            &:hover {
                background-color: $midRed;
            }
        }

        .simplebar-track.simplebar-vertical {
            right: 18px;
        }

        .container-fluid {
            max-width: 1200px;
            margin: auto;
        }

        .detail-content-layer {
            margin: auto;
            padding: 0 20px 0 110px;

            &:before {
                top: 350px;
            }
        }

        /*
        Title block + children
         */
        .title-row {
            padding-top: 120px;
            padding-bottom: 40px;
        }

        .item-title {
            @include fontSize(45px);
            @include lineHeight(45px);
            margin-bottom: 10px;
        }

        .item-label {
            @include fontSize(20px);
            @include lineHeight(24px);
        }

        /*
        Logo
         */
        .logo-col { display: block}
        .item-logo {
            display: flex;
            max-width: 100%;
            max-height: 100px;
            align-self: flex-end;
            margin-left: auto;
        }

        /*
        Intro-block
         */
        .intro-block {
            padding: 60px 0 90px 0;

            p {
                @include fontSize(28px);
                @include lineHeight(40px);
                font-family: $fontCrimson;
                margin: 0;
            }
        }

        .cta-row {
            margin: 60px 0 40px 0;
        }
    }

    //1200px +
    @include media-breakpoint-up(xl) {
        .detail-inner-wrap {
            padding: 0 0;
        }
    }
}


