/*
For fading in and out components
 */
$fadeAnimationTime: 250ms;


/*
Default fade in / ou
 */
.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity $fadeAnimationTime ease-in;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity $fadeAnimationTime ease-in;
}

.fade-exit-done {
    opacity: 0;
}

/*
Fade in and up
Fade out and down
 */
$translateY: 20px;
$fadeUpAnimationTime: 250ms;

.fade-up-enter {
    opacity: 0;
    transform: translateY($translateY);
}
.fade-up-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity $fadeUpAnimationTime ease-in, transform $fadeUpAnimationTime ease-in;
}

.fade-up-enter-active {
    opacity: 1;
    transform: translateY(0);
}

.fade-up-exit {
    opacity: 1;
    transform: translateY(0);
}
.fade-up-exit-active {
    opacity: 0;
    transform: translateY($translateY);
    transition: opacity $fadeUpAnimationTime ease-in, transform $fadeUpAnimationTime ease-in;
}

.fade-up-exit-done {
    opacity: 0;
    transform: translateY($translateY);
}

/*
Page Transitions
 */
.page-enter {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100vw;
}

.page-enter-done {
    opacity: 1;
}
