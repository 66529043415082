@import '../shared';

#primary-nav {

    /*
    Core, default mobile first styles here
    */
    height: 100vh;
    width: 160px;
    top: 0;
    left: -150px;
    position: absolute;

    .inner {
        @include animate-all(250ms);
        padding: 80px 45px 20px 0;
        text-align: right;
    }

    .bg-shape {
        @include animate-all(250ms);
        background: $darkRed;
    }
    .bg-shape {
        width: 100%;
        height: 100%;
        position: absolute;
        transform: translateX(-72px) skew(-$globalSkew);
    }

    .inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 80px 20px 0 25px;
        text-align: left;
    }

    /*
    Nav Link styles
     */
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }


    /*
    Nav Link styles
     */
    li {
        $listAnimateSpeed: 150ms;
        $listAnimateEase: cubic-bezier(.06,.81,.42,.99);
        transform: translateX(-150px);
        margin-bottom: 10px;
    }

    @for $i from 1 through 11 {
        $listAnimateSpeed: 150ms;
        $listAnimateEase: cubic-bezier(.06,.81,.42,.99);

        li:nth-child(#{$i}) {
            @include animate-all($listAnimateSpeed, #{50*($i+1)}ms, $ease: $listAnimateEase);
        }
    }

    a,
    button {
        @include fontSize(22px);
        @include animate-all(250ms);
        position: relative;
        font-family: $fontLobster;
        color: white;
        text-decoration: none;
        opacity: 0.8;

        &:before {
            width: 15px;
            height: 15px;
            position: absolute;
            left: -20px;
            top: 8px;
            content: "\e9d9";
            font-family: 'IcoMoon-Free';
            display: none;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
        }

        &:hover {
            opacity: 1;

            &:before {
                animation: rotateClockwise 5s infinite linear;
                display: block;
            }
        }
    }

    /*
    Secondary nav positioning
     */
    .secondary-nav-links {
        margin-top: auto;
        margin-bottom: 20px;
    }

    /*
    Open State
    */

    #global-header.nav-open & {
        .bg-shape {
            transform: translateX(88px) skew(-$globalSkew);
            width: 200px;
        }

        .inner {
            transform: translateX(150px);
        }


        li {
            transform: translateX(0px);
        }
    }


    //768px +
    @include media-breakpoint-up(md) {
        background: $darkRed;
        left: -160px;

        .inner {
            padding: 80px 20px 0 35px;
        }

        #global-header.nav-open & {
            .bg-shape {
                transform: translateX(100px) skew(-$globalSkew);
                width: 250px;
            }

            .inner {
                transform: translateX(150px);
            }

            li {
                transform: translateX(0px);
            }
        }

    }

    //992px +
    @include media-breakpoint-up(lg) {
        .secondary-nav-links {
            display: none;
        }

        #global-header.nav-open & {
            .bg-shape {
                transform: translateX(125px) skew(-$globalSkew);
                width: 200px;
            }
        }
    }
}
