@import '../shared';

.contact-form {

    /*
    Core, default mobile first styles here
    */
    position: fixed;
    display: flex;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    color: $notBlack;

    .component-inner {
        @include animate-custom(#{'transform'}, 250ms);
        position: relative;
        display: flex;
        align-self: center;
        width: 80%;
        max-width: 600px;
        margin: auto;
        background-color: #F8F3EF;
        border-bottom: solid 5px $darkRed
    }

    .inner-wrapper {
        width: 100%;
    }

    .btn-close {
        @include btnReset();
        position: absolute;
        right: -18px;
        top: -18px;
        width: 40px;
        height: 40px;
        z-index: 5;
        background-color: $darkRed;
        border-radius: 50%;
        color: $notWhite;

        .icon {
            @include fontSize(25px);
            position: relative;
            top: 2px;
            opacity: 0.6;
        }

        &:hover {
            .icon {
                opacity: 1;
            }
        }
    }

    .title-row {
        text-align: center;
        background-color: $darkRed;
        padding: 18px 0;

        p {
            @include fontSize(30px);
            font-family: $fontLobster;
            color: $notWhite;
            margin: 0;
        }
    }

    .content-block {
        padding: 20px 30px 30px 30px;

        p {
            @include fontSize(22px);
            @include lineHeight(30px);
            font-family: $fontCrimson;
            color: $notBlack
        }
    }

    .intro-block {
       display: none;
    }

    .input-row {
        padding-bottom: 20px;
    }

    label {
        @include fontSize(18px);
        @include lineHeight(26px);
        font-family: $fontCrimson;
        color: $notBlack;
        display: block;
        margin-bottom: 5px;
    }

    input[type='text'],
    textarea {
        @include fontSize(18px);
        @include lineHeight(26px);
        width: 100%;
        padding: 10px 15px;
        border: solid 1px darken(#F8F3EF, 5%);
        border-left: solid 2px $darkRed;
        font-family: $fontCrimson;

        &:active,
        &:focus {
            border: solid 1px darken(#F8F3EF, 20%);
            border-left: solid 2px $darkRed;
            outline: none;
        }
    }

    .control-row {
        text-align: center;
    }

    .btn-submit {
        outline: none;
        border: none;
    }

    .processing-overlay {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        margin-top: 60px;
        padding: 40px;
        background-color: #F8F3EF;
        z-index: 10;

        p {
            @include fontSize(18px);
            @include lineHeight(26px);
            font-family: $fontCrimson;
            text-align: center;
        }
    }

    .error-msg {
        position: absolute;
        background: $darkRed;
        left: 0;
        top: 0;
        height: 60px;
        width: 100%;
        padding: 11px 20px;

        p {
            @include fontSize(16px);
            @include lineHeight(18px);
            color: white;
        }
    }

    /*
    Transition the inner form wrapper
     */

    //Default, not rendered
    .component-inner {
        transform: translateY(50px);
    }

    &.fade-enter-active,
    &.fade-enter-done {
        .component-inner {
            transform: translateY(0);
        }
    }

    &.fade-exit-active {
        .component-inner {
            transform: translateY(50);
        }
    }

    //768px +
    @include media-breakpoint-up(md) {
        .intro-block {
            display: block;
            margin-bottom: 30px;
        }
    }
}
