@import '../shared';

.scribbles-gallery {
    $galleryGutter: 2px;
    $baseHeight: 150px;
    $baseHeight_lg: 200px;

    padding-bottom: 100px;

    .row {
        margin-left: -($galleryGutter/2);
        margin-right: -($galleryGutter/2);
    }

    .container-fluid,
    [class*='col-'] {
        padding: 0 $galleryGutter / 2;
    }

    .tile {
        @include btnReset();
        height: $baseHeight;
        width: 100%;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        margin-bottom: $galleryGutter;
    }

    .thumb {
        @include animate-custom(
            $animateProp: all,
            $animationTime: 150ms
        );
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        transform: scale(1.2);
        object-fit: cover;
        filter: grayscale(100%);
    }

    /*
    Hover state
     */
    .tile:hover {
        .thumb {
            filter: grayscale(0%);
            transform: scale(1.18);
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        .tile {
            height: $baseHeight_lg;
        }
    }
}