@import '../shared';

$portfolioAnimateSpeed: 250ms;

#portfolio {

    /*
    Preloading state
     */
    &.preloading {
        background: #f8f3ef;
        display: flex;
        justify-content: center;
        align-items: center;

        .inner-wrap {
            width: 60%;
            max-width: 400px;
        }
    }

    /*
    Core, default mobile first styles here
    */
    width: auto;
    background-color: $darkRed;

    .swiper-wrapper {
        transition: all $portfolioAnimateSpeed ease 0s !important;
    }

    .swiper-container {
        width: 100%;
        height: 100%;
    }

    .swiper-slide {
        transition: all $portfolioAnimateSpeed ease 0s !important;
    }

    /*
    Pagination overrides
     */
    .swiper-pagination {
        bottom: 20px;
        width: 100%;
    }

    .swiper-pagination-bullet {
        background: white;
        opacity: 0.5;
        width: 10px;
        height: 10px;
        margin: 0 10px;

        &.swiper-pagination-bullet-active {
            opacity: 1;
        }
    }

    /*
    Portfolio 'open' active state
     */
    .swiper-container.active {
        .swiper-pagination {
            display: none;
        }
    }

    /*
    Strange positioning issue with back buttons :(
     */
    @for $i from 1 through 12 {
        .swiper-slide:nth-child(#{$i}) {
            .btn-back {
                left: (($i - 1) * 100)+vw;
            }
        }
    }

    //992px +
    @include media-breakpoint-up(lg) {
        height: 100vh;
        width: 100vw;

        /*
        Pagination overrides
         */
        .swiper-pagination {
            top: 20px;
            bottom: initial;
            width: 100%;
        }

        .swiper-pagination-bullet {
            width: 15px;
            height: 15px;
            margin: 0 10px;

            &.swiper-pagination-bullet-active {
                opacity: 1;
            }
        }

        /*
        Portfolio 'open' active state
         */
        .swiper-container.active {
            .swiper-slide {
                width: 0 !important;
                opacity: 0 !important;
            }

            .swiper-slide.active {
                width: calc(100vw + 80px) !important;
                opacity: 1 !important;
            }

            .swiper-wrapper {
                transform: translate3d(0px, 0px, 0px) !important;
            }

            .swiper-pagination {
                display: none;
            }
        }

        @for $i from 1 through 12 {
            .swiper-slide:nth-child(#{$i}) {
                left: -($i+1)+px;

                .btn-back {
                    left: 100px;
                }
            }
        }
    }
}

