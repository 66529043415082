.content-page {

    /*
    Core, default mobile first styles here
    */
    min-height: 100vh;
    position: relative;
    padding: 80px 10px 0 10px;

    .inner-content {
        max-width: 1200px;
        margin: auto;
    }

    //992px +
    @include media-breakpoint-up(lg) {
        padding: 100px 50px 0 100px;
    }

    //1200px +
    @include media-breakpoint-up(xl) {
        padding: 100px 100px 0 100px;
    }
}


#content-wrapper {
    min-height: 100vh;
    overflow: hidden; //TODO - this is required to counteract the portfolio skew, but feels... wrong
    position: relative;
}


body {
    overflow-y: scroll;
}