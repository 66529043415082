@import '../shared';

#home-splash{

    $titleBlockHeight_default: 45px;
    $textBlockWidth: 380px;
    $titleSize: 34px;
    $titleLineHeight: 35px;
    $titleSpacing: 10px;

    $descBlockHeight_default: 146px;
    $descSize: 30px;
    $descLineHeight: 38px;
    $descSpacing: 25px;

    $titleBlockHeight_default: 45px;
    $descBlockHeight_default: 146px;

    /*
    Core, default mobile first styles here
    */
    background-image: url('../../imgs/home-splash-bg_sml.jpg');
    background-size: cover;

    .page {
        height: 100vh;
    }

    .content-layer {
        display: flex;
        width: 100%;
        height: 100%;
        position: relative;

        //Tint overlay
        &:before {
            @include animate-opacity (5000ms);
            content: '';
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            opacity: 1;
            background-color: darken(#1c0c00, 5%);
        }
    }

    .intro-text-block {
        position: relative;
        width: 300px;
        color: white;
        align-self: flex-end;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 40px;
    }

    /*
    Wrapping elements structure - required for animation states
     */
    .title-block {
        @include animate-custom(height, $ease: ease-out);
        height: 0;
        overflow: hidden;
    }

    .desc-block {
        @include animate-custom(
            $animateProp: height,
            $animationTime: 250ms,
            $ease: ease-out,
            $delay: $defaultAnimationTime * 7
        );
        height: 0;
        overflow: hidden;
        border-style: solid;
        border-color: white;
        border-width: 1px 0;
    }

    .title {
        @include fontSize(27px);
        @include lineHeight(30px);
        @extend %font-header;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
        margin-bottom: 10px;
    }

    .desc {
        @include fontSize(24px);
        @include lineHeight(34px);
        @extend %font-sub-header;
        font-weight: 100;
        margin: 0;
        padding: 15px 0;
    }

    .cta-row {
        margin: 20px 0;
        text-align: center;
        position: relative;
        overflow: hidden;
    }

    .portfolio-link {
        transform: translateY(-100%);
    }

    .rendered {

        .content-layer {
            &:before {
                opacity: 0.6;
            }
        }

        .intro-text-block {
            .title-block {
                height: $titleBlockHeight_default;
            }

            .desc-block {
                height: $descBlockHeight_default;
            }
        }

        .portfolio-link {
            transform: translateY(0);
        }
    }


    //992px +
    @include media-breakpoint-up(lg) {
        background-image: url('../../imgs/city.jpg');

        .web-gl-layer {
            position: absolute;
            left: 0;
            top: 0;
        }

        .intro-text-block {
            width: $textBlockWidth;
            position: relative;
            align-self: center;
            justify-self: center;
            margin: auto;
        }

        .title {
            @include fontSize($titleSize);
            @include lineHeight($titleLineHeight);
        }

        .desc {
            @include fontSize($descSize);
            @include lineHeight($descLineHeight);
        }
    }
}
